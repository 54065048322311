











































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import AuthModule from '@/store/modules/auth'
import { AuthRestore } from '@/store/types'

@Component({
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class RestorePasswordForm extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private token!: string

  private passwordConfirmValue = ''

  private form: AuthRestore = {
    password: '',
    token: '',
  }

  private isFocusPassword = false
  private typePassword = true
  private isFocusConfirmationPassword = false
  private typeConfirmationPassword = true

  private mounted () {
    const focusInput = ((this.$refs.focus as Vue).$el as HTMLElement).querySelector('input')

    if (focusInput) {
      focusInput.focus()
    }

    this.form.token = this.token
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form.password === this.passwordConfirmValue) {
            AuthModule.restoreConfirmation(this.form)
              .then(() => {
                requestAnimationFrame(() => (form.reset()))
                this.notifySuccess('Пароль обновлен')
                this.$router.push({ name: 'auth.login' })
              })
              .catch(this.notifyError)
          } else {
            this.notifyError('Пароли не совпадают')
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }
}
