











import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import { AuthRestore } from '@/store/types'
import AuthModule from '@/store/modules/auth'
import RestorePasswordForm from '@/components/forms/auth/RestorePasswordForm.vue'

@Component({
  components: { RestorePasswordForm },
})
export default class RestoreConfirmation extends Mixins(NotifyMixin) {
  private isConfirmed = false
  private form: AuthRestore = {
    token: '',
  }

  private created () {
    if (!this.$route.params.token) {
      this.$router.replace({ name: 'auth.login' })
      this.notifyError('Некорректный токен подтверждения')
    } else {
      this.form.token = this.$route.params.token
      this.handleSubmit()
    }
  }

  private handleSubmit () {
    AuthModule.restoreConfirmation(this.form)
      .then(() => {
        this.isConfirmed = true
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: this.isConfirmed ? 'Новый пароль' : 'Подтверждение восстановления пароля',
    }
  }
}
